<template>
  <base-modal-view :title="dealer.first_name + ' ' + dealer.last_name + ': Subscriptions'" @cancel="cancelClicked">
    <table class="results-table margin-top">
      <tr class="header">
        <th>Subscription</th>
        <th>On</th>
      </tr>
      <tr class="data-row">
        <td>Can Request Offers Even if Sales</td>
        <td>
          <Toggle
            class="toggle-iappraise"
            :class="{ 'toggle-off': !dealer.can_request_offers_even_if_sales }"
            v-model="dealer.can_request_offers_even_if_sales"
            @change="canRequestOffersEvenIfSalesToggled()"
          />
        </td>
      </tr>
      <tr class="data-row">
        <td>Blocked From Editing Offers</td>
        <td>
          <Toggle
            class="toggle-iappraise"
            :class="{ 'toggle-off': !dealer.is_blocked_from_offers }"
            v-model="dealer.is_blocked_from_offers"
            @change="blockedFromEditingOffersToggled()"
          />
        </td>
      </tr>
      <tr class="data-row">
        <td>Can Request PPSR</td>
        <td>
          <Toggle
            class="toggle-iappraise"
            :class="{ 'toggle-off': !dealer.can_request_ppsr }"
            v-model="dealer.can_request_ppsr"
            @change="dealerRequestPPSRToggled(dealer)"
          />
        </td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { mapGetters, mapActions } from "vuex";
import Toggle from "@vueform/toggle";
import { update } from "@/api/Dealer.js";

export default {
  name: "NewPermissionsModal",
  components: { BaseModalView, Toggle },
  props: ["editingDealer"],
  data: function () {
    return {
      dealer: {
        can_request_offers_even_if_sales: false,
        is_blocked_from_offers: false
      }
    };
  },
  methods: {
    claimClicked: function () {
      this.$emit("claimDealer", this.claim);
    },
    submitClicked: function () {
      this.$emit("createDealer", this.dealer);
    },
    canRequestOffersEvenIfSalesToggled: function () {
      let dealer = {
        url: this.dealer.url,
        can_request_offers_even_if_sales: this.dealer.can_request_offers_even_if_sales
      };
      update(dealer).catch(error => {
        this.addError(error);
      });
    },
    blockedFromEditingOffersToggled: function () {
      let dealer = {
        url: this.dealer.url,
        is_blocked_from_offers: this.dealer.is_blocked_from_offers
      };
      update(dealer).catch(error => {
        this.addError(error);
      });
    },
    dealerRequestPPSRToggled: function (dealer) {
      this.setCanRequestPPSR({ dealer: dealer });
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    ...mapActions({
      setCanRequestPPSR: "dealershipStore/setCanRequestPPSR",
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships"
    })
  },
  mounted: function () {
    if (this.editingDealer) {
      this.dealer = this.editingDealer;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.toggle-iappraise {
  --toggle-bg-on: #121212;
  --toggle-border-on: #121212;
  --toggle-bg-off: white;
  --toggle-border-off: #121212;
}

.toggle-off {
  --toggle-handle-enabled: #121212;
}
</style>
