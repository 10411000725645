<template>
  <div>
    <div id="permissions-page">
      <h1 class="h1-title">PPSR and user permissions management</h1>
      <div class="flex-row margin-top-2">
        <secondary-button
          title="Add new appraiser"
          icon="/assets/img/icon-plus.svg"
          class="min-width-192 margin-right-05"
          :disabled="selectedDealers.length > 0"
          v-on:click="selectedDealers.length > 0 ? () => {} : (() => (addingDealership = true))()"
        />
        <search-bar
          class="margin-right flex-grow"
          availableFilterMode="permissions"
          placeholder="Search for name, phone number or email"
          :emitActions="true"
          @applyFilter="applyFilter"
        >
          <secondary-button
            icon="/assets/img/icon-trash.svg"
            title="Delete"
            class="min-width-120 margin-left-05"
            :disabled="!(selectedDealers.length > 0)"
            v-on:click="deleteDealer()"
          />
          <div class="position-relative">
            <secondary-button
              title="Change Role"
              class="min-width-120 margin-left-05"
              :disabled="!(selectedDealers.length > 0)"
              v-on:click="showChangeRole = true"
            />
            <div v-if="showChangeRole" class="dropdown shadow padding-1-05 min-width-320 flex-column">
              <div class="flex-row">
                <h4 class="h4 margin-right-auto vertical-flex-center">Role</h4>
                <button class="no-button-style" v-on:click="showChangeRole = false">
                  <img src="/assets/img/icon-close.svg" />
                </button>
              </div>
              <form @submit.prevent="updateRole()" class="flex-column">
                <select required v-model="newRole" class="dropdown-field margin-top-1-05">
                  <option value="">--- Select ---</option>
                  <option value="true">Management</option>
                  <option value="false">Sales</option>
                </select>
                <div class="flex-row">
                  <primary-button title="Apply" class="margin-top margin-left-auto min-width-100" type="submit" />
                </div>
              </form>
            </div>
          </div>
        </search-bar>
      </div>
      <filter-bar :customFilters="filters" @removeFilter="removeFilter" />
      <div class="results-table-container margin-top">
        <table class="results-table">
          <tr class="header">
            <th>
              <input v-model="selectAllCheckbox" type="checkbox" name="" id="" />
            </th>
            <th>Dealer Name</th>
            <th>Dealership</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>User Role</th>
            <th>Can Request PPSR</th>
            <th>Options</th>
          </tr>
          <tr
            v-for="dealer in filteredDealers()
              .filter(dealer => dealer.is_active)
              .sort((a, b) => a.last_name - b.last_name)"
            v-bind:key="dealer.id"
            class="data-row"
          >
            <td>
              <input type="checkbox" name="" id="" v-model="selectedDealers" :value="dealer" />
            </td>
            <td>{{ dealer.first_name }} {{ dealer.last_name }}</td>
            <td class="dealership">{{ dealershipName(dealer.dealership) }}</td>
            <td>{{ dealer.email }}</td>
            <td>{{ dealer.phone_number }}</td>
            <td>{{ dealer.is_manager ? "Manager" : "Sales" }}</td>
            <td>
              <Toggle
                class="toggle-iappraise"
                :class="{ 'toggle-off': !dealer.can_request_ppsr }"
                v-model="dealer.can_request_ppsr"
                @change="dealerRequestPPSRToggled(dealer)"
              />
            </td>
            <td>
              <img
                v-on:click="dropdownDealer = dropdownDealer ? null : dealer"
                src="/assets/img/icon-more-dots.svg"
                class="small-icon pointer-cursor"
              />
              <div v-if="dropdownDealer == dealer" class="dropdown shadow body-2 padding-vertical-025 min-width-192">
                <div
                  class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  v-on:click="
                    editSelectedDealer(dealer);
                    dropdownDealer = null;
                  "
                >
                  Edit Profile
                </div>
                <div
                  class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  v-on:click="
                    editingDealerIntegration = dealer;
                    dropdownDealer = null;
                  "
                >
                  Edit Integration Details
                </div>
                <div
                  class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  v-on:click="
                    editingDealerSubscriptions = dealer;
                    dropdownDealer = null;
                  "
                >
                  Edit Subscriptions Details
                </div>
                <div
                  class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  v-if="currentDealer.is_manager"
                  v-on:click="
                    editingDealerPermissions = dealer;
                    dropdownDealer = null;
                  "
                >
                  Edit Permissions
                </div>
                <div
                  class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  v-if="currentDealer.id == dealer.id"
                  v-on:click="
                    editingDealerPin = dealer;
                    dropdownDealer = null;
                  "
                >
                  Update PIN
                </div>
                <div
                  class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  v-if="currentDealer.is_manager && currentDealer.id != dealer.id"
                  v-on:click="
                    resetPinFunction(dealer);
                    dropdownDealer = null;
                  "
                >
                  Reset PIN
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <new-dealer-modal
        v-if="addingDealership || editingDealer"
        :editingDealer="editingDealer"
        @createDealer="callCreateDealer"
        @claimDealer="callClaimDealer"
        @cancel="
          addingDealership = null;
          editingDealer = null;
        "
      />

      <dealer-integration-details-modal
        v-if="editingDealerIntegration"
        :editingDealer="editingDealerIntegration"
        @cancel="editingDealerIntegration = null"
      />

      <dealer-subscriptions-modal
        v-if="editingDealerSubscriptions"
        :editingDealer="editingDealerSubscriptions"
        @cancel="editingDealerSubscriptions = null"
      />

      <dealer-permissions-modal
        v-if="editingDealerPermissions"
        :editingDealer="editingDealerPermissions"
        @cancel="editingDealerPermissions = null"
      />

      <update-pin-modal
        v-if="editingDealerPin"
        :dealer="editingDealerPin"
        @submitted="editingDealerPin = null"
        @cancel="editingDealerPin = null"
      />

      <accept-tn-c-modal v-if="showAcceptTnCs" @cancel="showAcceptTnCs = false" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import NewDealerModal from "../../components/Modals/v2/NewDealerModal.vue";
import DealerSubscriptionsModal from "../../components/Modals/v2/DealerSubscriptionsModal.vue";
import DealerPermissionsModal from "../../components/Modals/v2/DealerPermissionsModal.vue";
import DealerIntegrationDetailsModal from "../../components/Modals/v2/DealerIntegrationDetailsModal.vue";
import UpdatePinModal from "../../components/Modals/v2/UpdatePinModal.vue";
import AcceptTnCModal from "../../components/Modals/v2/AcceptTnCModal.vue";
import Toggle from "@vueform/toggle";

import SearchBar from "../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../components/Views/v2/FilterBar.vue";
import PrimaryButton from "../../components/Buttons/v2/PrimaryButton.vue";
import SecondaryButton from "../../components/Buttons/v2/SecondaryButton.vue";

import { resetPin } from "../../api/Dealer.js";

import _ from "lodash";

export default {
  name: "Permissions",
  components: {
    NewDealerModal,
    DealerIntegrationDetailsModal,
    DealerSubscriptionsModal,
    DealerPermissionsModal,
    UpdatePinModal,
    AcceptTnCModal,
    Toggle,

    SearchBar,
    FilterBar,
    PrimaryButton,
    SecondaryButton
  },
  data: function () {
    return {
      searchText: "",
      addingDealership: null,
      dropdownDealer: null,
      editingDealer: null,
      editingDealerIntegration: null,
      editingDealerSubscriptions: null,
      editingDealerPermissions: null,
      editingDealerPin: null,
      selectAllCheckbox: false,
      selectedDealers: [],
      showAcceptTnCs: false,
      showChangeRole: false,
      newRole: "",
      filters: {}
    };
  },
  computed: {
    ...mapGetters({
      dealers: "dealershipStore/dealers",
      dealerships: "dealershipStore/dealerships",
      dealershipWithId: "dealershipStore/dealershipWithId"
    }),
    ...mapState({
      currentDealership: state => state.dealershipStore.currentDealership,
      currentDealer: state => state.dealershipStore.currentDealer
    })
  },
  methods: {
    callCreateDealer: function (dealer) {
      if (dealer.url) {
        this.updateDealer(dealer);
        this.editingDealer = null;
      } else {
        this.createDealer(dealer);
        this.addingDealership = null;
      }
      this.selectedDealers = [];
    },
    callClaimDealer: function (claim) {
      this.claimDealer(claim);
      this.addingDealership = null;
    },
    editSelectedDealer: function (dealer) {
      this.editingDealer = _.cloneDeep(dealer);
    },
    deleteDealer: function () {
      if (this.selectedDealers.length <= 0) {
        return;
      }
      var confirmation = confirm(
        "Are you sure you want to delete " +
          (this.selectedDealers.length > 1 ? this.selectedDealers.length : "this") +
          " User" +
          (this.selectedDealers.length > 1 ? "s" : "") +
          "?"
      );
      if (confirmation) {
        this.selectedDealers.forEach(dealer => {
          dealer.is_active = false;
          this.updateDealer(dealer);
        });
        this.selectedDealers = [];
      }
    },
    updateRole: function () {
      if (this.selectedDealers.length <= 0) {
        return;
      }
      var confirmation = confirm(
        "Are you sure you want to update the role on " +
          (this.selectedDealers.length > 1 ? this.selectedDealers.length : "this") +
          " User" +
          (this.selectedDealers.length > 1 ? "s" : "") +
          "?"
      );
      if (confirmation) {
        this.selectedDealers.forEach(dealer => {
          dealer.is_manager = this.newRole;
          this.updateDealer(dealer);
        });
      }
      this.selectedDealers = [];
      this.showChangeRole = false;
    },
    filteredDealers: function () {
      var toReturn = this.dealers;
      if ("quick_search" in this.filters) {
        var quickSearch = this.filters["quick_search"];
        if (quickSearch != "") {
          toReturn = toReturn.filter(dealer => {
            if (
              !dealer.first_name.toLowerCase().includes(quickSearch.toLowerCase()) &&
              !dealer.last_name.toLowerCase().includes(quickSearch.toLowerCase()) &&
              !dealer.email.toLowerCase().includes(quickSearch.toLowerCase()) &&
              !dealer.phone_number.toLowerCase().includes(quickSearch.toLowerCase())
            ) {
              return false;
            }

            return true;
          });
        }
      }
      if ("dealership" in this.filters) {
        toReturn = toReturn.filter(dealer => {
          return dealer.dealership == this.filters["dealership"];
        });
      }
      if ("dealership_not" in this.filters) {
        toReturn = toReturn.filter(dealer => {
          return dealer.dealership != this.filters["dealership_not"];
        });
      }
      if ("user_role" in this.filters) {
        toReturn = toReturn.filter(dealer => {
          return dealer.is_manager == this.filters["user_role"];
        });
      }
      if ("can_request_ppsr" in this.filters) {
        toReturn = toReturn.filter(dealer => {
          return dealer.can_request_ppsr == this.filters["can_request_ppsr"];
        });
      }

      return toReturn;
    },
    dealershipName: function (id) {
      let dealership = this.dealershipWithId(id);
      if (dealership) {
        return dealership.first_name;
      } else {
        return "-";
      }
    },
    applyFilter: function (filter) {
      var newFilters = _.cloneDeep(this.filters);
      newFilters[filter.filter.key] = filter.filter.value;
      this.filters = newFilters;
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    dealerRequestPPSRToggled: function (dealer) {
      this.setCanRequestPPSR({ dealer: dealer });
    },
    resetPinFunction: function (dealer) {
      var confirmation = confirm(
        "Are you sure you want to reset the PIN for " + dealer.first_name + " " + dealer.last_name + "?"
      );
      if (confirmation) {
        resetPin(dealer);
        alert(
          "The PIN has been reset for " +
            dealer.first_name +
            " " +
            dealer.last_name +
            ". They should receive an email with their new PIN shortly."
        );
      }
    },
    ...mapActions({
      promoteDealer: "dealershipStore/promoteDealer",
      createDealer: "dealershipStore/createDealer",
      updateDealer: "dealershipStore/updateDealer",
      claimDealer: "dealershipStore/claimDealer",
      setCanRequestPPSR: "dealershipStore/setCanRequestPPSR"
    })
  },
  watch: {
    selectAllCheckbox: function (newVal) {
      if (newVal) {
        this.selectedDealers = this.dealers;
      } else {
        this.selectedDealers = [];
      }
    }
  },
  mounted: function () {
    this.showAcceptTnCs =
      this.currentDealership.ppsr_permission && !this.currentDealership.ppsr_tnc && this.currentDealer.is_super_manager;
  }
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#permissions-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.toggle-iappraise {
  --toggle-bg-on: #121212;
  --toggle-border-on: #121212;
  --toggle-bg-off: white;
  --toggle-border-off: #121212;
}

.toggle-off {
  --toggle-handle-enabled: #121212;
}

.dropdown {
  left: auto;
}
</style>
