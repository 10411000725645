<template>
  <base-modal-view title="Edit User" @cancel="cancelClicked">
    <form class="flex-column" @submit.prevent="submitClicked">
      <h3 class="h3-subtitle no-margin margin-top">Revolution</h3>
      <p class="no-margin margin-top-05 label">Revolution's Tune Sales Code</p>
      <input
        v-model="dealer.rev_sales_code"
        type="text"
        class="margin-top-025 text-field"
        placeholder="rev_sales_code"
      />
      <h3 class="h3-subtitle no-margin margin-top">AutoIT</h3>
      <p class="no-margin margin-top-05 label">AutoIT's Units Contact Code</p>
      <input
        v-model="dealer.units_contact_code"
        type="text"
        class="margin-top-025 text-field"
        placeholder="units_contact_code"
      />
      <p class="no-margin margin-top-05 label">AutoIT's PMDS Contact Code</p>
      <input
        v-model="dealer.pmds_contact_code"
        type="text"
        class="margin-top-025 text-field"
        placeholder="pmds_contact_code"
      />
      <h3 class="h3-subtitle no-margin margin-top">eGoodManners</h3>
      <p class="no-margin margin-top-05 label">eGoodManners Lead Dealer Code</p>
      <input
        v-model="dealer.egm_lead_dealer_code"
        type="text"
        class="margin-top-025 text-field"
        placeholder="egm_lead_dealer_code"
      />
      <h3 class="h3-subtitle no-margin margin-top">Pentana</h3>
      <p class="no-margin margin-top-05 label">Pentana's ERAPower User Id</p>
      <input
        v-model="dealer.erapower_user_id"
        type="text"
        class="margin-top-025 text-field"
        placeholder="erapower_user_id"
      />
      <p class="no-margin margin-top-05 label">Pentana's ERAPower Salesman Number</p>
      <input
        v-model="dealer.erapower_salesman_no"
        type="text"
        class="margin-top-025 text-field"
        placeholder="erapower_salesman_no"
      />
      <p class="no-margin margin-top-05 label">Pentana's ERAPower Sales Type</p>
      <input
        v-model="dealer.erapower_sales_type"
        type="text"
        class="margin-top-025 text-field"
        placeholder="erapower_sales_type"
      />
      <h3 class="h3-subtitle no-margin margin-top">Titan</h3>
      <p class="no-margin margin-top-05 label">Titan Salesperson ID</p>
      <input
        v-model="dealer.titan_salesperson_id"
        type="text"
        class="margin-top-025 text-field"
        placeholder="titan_salesperson_id"
      />
      <primary-button class="margin-top" title="Save" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapGetters } from "vuex";

import { getDealerIntegrationDetails, updateDealerIntegrationDetails } from "../../../api/Dealer.js";

export default {
  name: "DealerIntegrationDetailsModal",
  components: { PrimaryButton, BaseModalView },
  props: ["editingDealer"],
  data: function () {
    return {
      dealer: {
        rev_sales_code: "",
        units_contact_code: "",
        pmds_contact_code: "",
        egm_lead_dealer_code: "",
        erapower_user_id: "",
        erapower_salesman_no: "",
        erapower_sales_type: "",
        titan_salesperson_id: ""
      }
    };
  },
  methods: {
    submitClicked: function () {
      updateDealerIntegrationDetails(this.dealer).then(() => {
        this.$emit("cancel");
      });
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships"
    })
  },
  mounted: function () {
    if (this.editingDealer) {
      getDealerIntegrationDetails(this.editingDealer).then(response => {
        this.dealer = response;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
