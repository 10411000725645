<template>
  <base-modal-view :title="editingDealer ? 'Edit User' : 'Add User'" @cancel="cancelClicked">
    <div class="flex-row">
      <primary-button
        :title="forClaim ? 'Add New User' : 'Claim User'"
        v-if="!editingDealer"
        v-on:click="forClaim = !forClaim"
        class="margin-left-auto margin-top"
      />
    </div>
    <form v-if="forClaim" class="flex-column" @submit.prevent="claimClicked">
      <p class="no-margin margin-top label">Dealer ID</p>
      <input
        required
        v-model="claim.dealer_id"
        type="number"
        class="margin-top-025 text-field"
        placeholder="Dealer ID"
      />
      <p class="no-margin margin-top label">PIN</p>
      <input required v-model="claim.pin" type="number" class="margin-top-025 text-field" placeholder="PIN" />
      <primary-button class="margin-top" title="Claim" />
    </form>
    <form v-if="!forClaim" class="flex-column" @submit.prevent="submitClicked">
      <p class="no-margin margin-top label">Dealership</p>
      <select class="margin-top-025 dropdown-field" required v-model="dealer.dealership" v-if="!editingDealer">
        <option value="">-- Select a Dealership --</option>
        <option v-for="dealership in dealerships" v-bind:key="dealership.id" :value="dealership.url">
          {{ dealership.first_name }}
        </option>
      </select>
      <p class="no-margin margin-top label">First Name</p>
      <input v-model="dealer.first_name" type="text" class="margin-top-025 text-field" placeholder="First Name" />
      <p class="no-margin margin-top label">Last Name</p>
      <input v-model="dealer.last_name" type="text" class="margin-top-025 text-field" placeholder="Last Name" />
      <p class="no-margin margin-top label">Phone</p>
      <input v-model="dealer.phone_number" type="phone" class="margin-top-025 text-field" placeholder="Phone" />
      <p class="no-margin margin-top label">Email</p>
      <input required v-model="dealer.email" type="email" class="margin-top-025 text-field" placeholder="Email" />
      <p v-if="!editingDealer" class="no-margin margin-top label">PIN</p>
      <input
        v-model="dealer.pin"
        type="password"
        class="margin-top-025 text-field"
        placeholder="Enter your PIN"
        v-if="!editingDealer"
      />
      <p class="no-margin margin-top label">Role</p>
      <select class="margin-top-025 dropdown-field" required v-model="dealer.is_manager">
        <option value="">-- Sales Or Management --</option>
        <option value="false">Sales</option>
        <option value="true">Management</option>
      </select>
      <primary-button class="margin-top" title="Submit" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapGetters } from "vuex";

export default {
  name: "NewDealerModal",
  components: { PrimaryButton, BaseModalView },
  props: ["editingDealer"],
  data: function () {
    return {
      forClaim: false,
      claim: {
        dealer_id: "",
        pin: ""
      },
      dealer: {
        dealership: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        pin: "",
        is_manager: ""
      }
    };
  },
  methods: {
    claimClicked: function () {
      this.$emit("claimDealer", this.claim);
    },
    submitClicked: function () {
      this.$emit("createDealer", this.dealer);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships"
    })
  },
  mounted: function () {
    if (this.editingDealer) {
      this.dealer = this.editingDealer;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
