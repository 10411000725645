<template>
  <base-modal-view title="PPSR Terms and Conditions" @cancel="cancelClicked">
    <form class="flex-column" @submit.prevent="submitClicked">
      <div class="text-container margin-top margin-bottom">
        <pre style="white-space: pre-wrap">
        Dear Valued Client,

        Thank you for choosing to utilise our PPSR reports for your business. Please review the following information and acknowledge you have read and understood our terms and conditions of this PPSR service by pressing the button below. You will be charged an account set up fee of $300 + GST per site. PPSR reports are charged at $3 including GST each and are invoiced monthly in arrears. You can select which users can order a PPSR by clicking a users name under the permissions section on the iAppraise desktop.

        <h4 style="text-align:center; text-decoration: underline; font-size: inherit;">PPSR Information & Advice</h4>
        Tasmanian stolen vehicle information is currently unavailable from this service. For information about the status of Tasmanian vehicles, go to:
        https://www.transport.tas.gov.au/MRSWebInterface/public/regoLookup/registrationLookup.jsf
        (Note: you will need to provide the vehicle registration plate number to search this site).

        A stolen vehicle notification, or the absence of one, does not necessarily mean a vehicle is or is not stolen.


        If there is a stolen vehicle notification you wish to enquire about, you should contact the police force in the State or Territory indicated immediately after the date in the notification. The reference number at the end of the notification should be quoted as a part of your enquiry.

        Please note that there may be multiple stolen notifications shown. A stolen notification can be for a VIN, Plate, Chassis and/or Engine.

        Vehicle information is requested from Austroads NEVDIS database for each transaction involving a vehicle VIN or Chassis Number on the PPSR. The data is not part of interests registered on the PPSR and it is presented without any guarantee regarding its accuracy.

        The NEVDIS data is intended to assist users with checking that the VIN or Chassis Number they entered, along with information they have about the vehicle from other sources, is accurate and to provide users with any data held on NEVDIS about a vehicle’s stolen or written off status.

        State and territory road agencies are the source of NEVDIS data and the primary point of contact for enquiries, errors and corrections.

        Access to and use of data provided through the PPSR is subject to the PPSR General Conditions of Use and applicable third party data conditions (which can be obtained at www.ppsr.gov.au). To the extent permitted by law, all data provided through the PPSR is made available without any representation or warranty of any kind (without limitation in respect to accuracy) and the Commonwealth, and our third party data providers (which includes any person mentioned in the Third Party Data Conditions as providing information through the PPSR), have no liability to you in respect of any loss or damage that you might suffer no matter how arising (including negligence) that is directly or indirectly related to the NEVDIS Data.

        

        <b>How to verify this certificate on the PPSR</b>

        You can use the search number from an original search (as shown on this certificate) to retrieve the original search results and to issue a copy of the search certificate at https://transact.ppsr.gov.au/ppsr/Home.

        There is no fee, however this process will not provide any update to the information in the original search.

        

        <b>Privacy and Terms and Conditions</b>

        The Australian Financial Security Authority is subject to the Privacy Act 1988 which requires that we comply with the Australian Privacy Principles (APPs) set out in the Act. The APPs set out how Australian Government agencies should collect, use, store and disclose personal information and how individuals can access records containing their personal information.

        Access to and use of the PPSR is subject to the General Conditions of Use, as well as other relevant terms and conditions. All relevant terms and conditions can be found at www.ppsr.gov.au
        </pre>
      </div>
      <primary-button class="vertical-spacing-half auto-margin" title="Accept" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapActions } from "vuex";

export default {
  name: "AcceptTnCModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      name: ""
    };
  },
  methods: {
    submitClicked: function () {
      this.acceptPPSRTnCs();
      this.$emit("cancel");
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    ...mapActions({
      acceptPPSRTnCs: "dealershipStore/acceptPPSRTnCs"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.text-container {
  @extend .body-2;
  @extend .rounded-corners;

  max-height: 400px;
  overflow: auto;
  border: 1px solid #bbb;
  padding: $PADDING_X05;
}
</style>
