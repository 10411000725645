<template>
  <base-modal-view v-if="dealer" :title="dealer.first_name + ' ' + dealer.last_name" @cancel="cancelClicked">
    <form @submit.prevent="submitClicked" class="flex-column">
      <label for="current" class="text-field-title margin-top">Current Pin</label>
      <input
        v-model="updateDict.current_pin"
        class="margin-top-025 text-field"
        type="password"
        placeholder="Current PIN"
        name=""
        id="current"
        required
      />
      <label for="new" class="text-field-title margin-top-025">New Pin</label>
      <input
        v-model="updateDict.new_pin"
        class="margin-top-025 text-field"
        type="password"
        placeholder="New PIN"
        name=""
        id="new"
        required
      />
      <label for="confirm" class="text-field-title margin-top-025">Confirm New Pin</label>
      <input
        v-model="updateDict.confirm_new_pin"
        class="margin-top-025 text-field"
        type="password"
        placeholder="New PIN"
        name=""
        id="confirm"
        required
      />
      <primary-button
        class="margin-top auto-margin"
        :title="loading ? 'Submitting...' : 'Submit'"
        :disabled="disabled()"
      />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { updatePin } from "../../../api/Dealer.js";
import { mapActions } from "vuex";

export default {
  name: "UpdatePinModal",
  components: { PrimaryButton, BaseModalView },
  props: ["dealer"],
  data: function () {
    return {
      loading: false,
      updateDict: {
        current_pin: "",
        new_pin: "",
        confirm_new_pin: ""
      }
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    submitClicked: function () {
      if (this.loading) return;
      this.loading = true;
      updatePin(this.dealer, this.updateDict)
        .then(response => {
          this.loading = false;
          if (!response.success) {
            this.addError("Something went wrong. Maybe your PIN is incorrect.");
            return;
          }
          this.setCurrentDealerPIN({
            dealer: this.dealer,
            pin: this.updateDict.new_pin
          });
          this.$emit("cancel");
        })
        .catch(error => {
          this.loading = false;
          this.addError(error);
        });
    },
    disabled: function () {
      return (
        this.updateDict.current_pin === "" ||
        this.updateDict.new_pin === "" ||
        this.updateDict.confirm_new_pin === "" ||
        this.updateDict.new_pin != this.updateDict.confirm_new_pin
      );
    },
    ...mapActions({
      setCurrentDealerPIN: "dealershipStore/setCurrentDealerPIN",
      addError: "errorStore/addError"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
