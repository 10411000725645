<template>
  <base-modal-view :title="dealer.first_name + ' ' + dealer.last_name + ': Subscriptions'" @cancel="cancelClicked">
    <table class="results-table margin-top">
      <tr class="header">
        <th>Subscription</th>
        <th>On</th>
      </tr>
      <tr class="data-row">
        <td>Subscribed for All Offer Notifications</td>
        <td>
          <Toggle
            class="toggle-iappraise"
            :class="{ 'toggle-off': !dealer.is_subscribed_for_all_offer_notifications }"
            v-model="dealer.is_subscribed_for_all_offer_notifications"
            @change="dealerAllOffersToggled()"
          />
        </td>
      </tr>
      <tr class="data-row">
        <td>Subscribed for Emails</td>
        <td>
          <Toggle
            class="toggle-iappraise"
            :class="{ 'toggle-off': !dealer.is_subscribed_for_emails }"
            v-model="dealer.is_subscribed_for_emails"
            @change="dealerEmailsToggled()"
          />
        </td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { mapGetters, mapActions } from "vuex";
import Toggle from "@vueform/toggle";
import { update } from "@/api/Dealer.js";

export default {
  name: "DealerSubscriptionsModal",
  components: { BaseModalView, Toggle },
  props: ["editingDealer"],
  data: function () {
    return {
      dealer: {
        is_subscribed_for_emails: false,
        is_subscribed_for_all_offer_notifications: false
      }
    };
  },
  methods: {
    claimClicked: function () {
      this.$emit("claimDealer", this.claim);
    },
    submitClicked: function () {
      this.$emit("createDealer", this.dealer);
    },
    dealerEmailsToggled: function () {
      let dealer = {
        url: this.dealer.url,
        is_subscribed_for_emails: this.dealer.is_subscribed_for_emails
      };
      update(dealer).catch(error => {
        this.addError(error);
      });
    },
    dealerAllOffersToggled: function () {
      let dealer = {
        url: this.dealer.url,
        is_subscribed_for_all_offer_notifications: this.dealer.is_subscribed_for_all_offer_notifications
      };
      update(dealer).catch(error => {
        this.addError(error);
      });
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships"
    })
  },
  mounted: function () {
    if (this.editingDealer) {
      this.dealer = this.editingDealer;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.toggle-iappraise {
  --toggle-bg-on: #121212;
  --toggle-border-on: #121212;
  --toggle-bg-off: white;
  --toggle-border-off: #121212;
}

.toggle-off {
  --toggle-handle-enabled: #121212;
}
</style>
